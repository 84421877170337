import { useCallback, useState } from 'react';
import { notifyErrors } from 'utils/notifications';

function useArchiveCount({ sdk, clear, filterBy, filters }) {
  const [logsCount, setLogsCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchLogCount = useCallback(async () => {
    setLoading(true);
    const { data, success, errors } = await sdk();
    setLoading(false);

    if (!success) {
      notifyErrors(errors);
      return;
    }

    setLogsCount(data.count);
  }, [sdk]);

  const clearFilters = useCallback(() => {
    setLogsCount(null);
    clear();
  }, [clear]);

  const filterAndClearCount = useCallback(
    values => {
      setLogsCount(null);
      filterBy(values);
    },
    [filterBy]
  );

  const hasAppliedFilters = !Object.values(filters).every(
    value =>
      value === null ||
      value === '' ||
      (Array.isArray(value) && value.length === 0)
  );

  return {
    fetchLogCount,
    logsCount,
    filters,
    clearFilters,
    filterAndClearCount,
    hasAppliedFilters,
    loading
  };
}

export default useArchiveCount;
