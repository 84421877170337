import { transformValuesForMultiselect } from 'utils/filters';

import {
  InputField,
  DateField,
  UniSelect,
  AdminSelect,
  SubscriptionStatusSelect
} from 'components';

export const FILTERS = [
  [
    {
      name: 'open_from',
      emptyValue: null,
      component: DateField,
      label: 'Opened From',
      width: 4
    },
    {
      name: 'open_to',
      emptyValue: null,
      component: DateField,
      label: 'Opened to',
      width: 4
    }
  ],
  [
    {
      name: 'price',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: UniSelect,
      label: 'Price Serie',
      props: () => ({
        source: 'priceSeriesAll'
      }),
      width: 6
    },
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account Manager',
      width: 4
    },
    {
      name: 'subscription_status',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription status',
      props: () => ({
        isMulti: true
      }),
      width: 6
    }
  ],
  [
    {
      name: 'user',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      width: 2,
      type: 'number'
    },
    {
      name: 'email',
      emptyValue: '',
      component: InputField,
      label: 'Email address',
      width: 4
    },
    {
      name: 'first_name',
      emptyValue: '',
      component: InputField,
      label: 'First name',
      width: 3
    },
    {
      name: 'last_name',
      emptyValue: '',
      component: InputField,
      label: 'Last name',
      width: 3
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company name',
      width: 4
    }
  ]
];
