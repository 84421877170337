export const pluralize = (word, count, suffix = 's') => {
  const pluralRules = new Intl.PluralRules('en', { type: 'cardinal' });
  const pluralForm = pluralRules.select(count);

  if (pluralForm === 'one') {
    return word;
  }

  return word + suffix;
};
