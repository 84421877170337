import React from 'react';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import _ from 'lodash';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { formatDateTime } from 'utils';
import { TableFooter, SortableHeader } from 'components';

import styles from './styles.module.css';

const ClientProfileLogTable = ({ profileLogs, sort, orderBy, pagination }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="6">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>Staff</Table.HeaderCell>
        <Table.HeaderCell>Client ID</Table.HeaderCell>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
          Updated At
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="field_name">
          Item
        </SortableHeader>
        <Table.HeaderCell>Old Value</Table.HeaderCell>
        <Table.HeaderCell>New Value</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {profileLogs.map(log => (
        <Table.Row key={log.id}>
          <Table.Cell width={1}>{log.staff_name}</Table.Cell>
          <Table.Cell width={1}>
            <Link to={pageUrl(ClientDetail, { clientId: log.client_id })}>
              {log.client_id}
            </Link>
          </Table.Cell>
          <Table.Cell width={2}>{formatDateTime(log.created_at)}</Table.Cell>
          <Table.Cell width={1}>{log.field_name}</Table.Cell>
          <Table.Cell width={4} className={styles.LargeText}>
            {_.truncate(log.old_value, { length: 255 })}
          </Table.Cell>
          <Table.Cell className={styles.LargeText} width={4}>
            {_.truncate(log.new_value, { length: 255 })}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default ClientProfileLogTable;
