import _ from 'lodash';
import React from 'react';

import { withRouter } from 'react-router-dom';

import {
  Page,
  Header,
  Flex,
  LoginRequired,
  ListViewEssentials,
  CursorPagination,
  NoDataMessage,
  Filters as SharedFilters
} from 'components';
import useArchiveCount from 'hooks/useArchiveCount';

import { emailLogCount, emailLogList } from './sdk';

import { LogsTable, withLogType } from '../components';

import { FILTERS } from './utils';

const EmailLogList = ({
  user,
  data,
  nextCursor,
  previousCursor,
  pageSize,
  changePage,
  changeRowsPerPage,
  clear,
  filterBy,
  filters,
  logType,
  mailTargetName
}) => {
  const {
    fetchLogCount,
    logsCount,
    clearFilters,
    filterAndClearCount,
    hasAppliedFilters,
    loading
  } = useArchiveCount({
    sdk: _.partial(emailLogCount, logType, filters),
    clear,
    filterBy,
    filters
  });

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content>
          <Flex spaceBetween>
            <Header>Archive {_.capitalize(logType)} Email</Header>
          </Flex>
          {data && (
            <>
              <SharedFilters
                filters={filters}
                filterBy={filterAndClearCount}
                clearFilters={clearFilters}
                count={logsCount}
                layout={FILTERS(mailTargetName)}
              />

              {_.isEmpty(data) && <NoDataMessage />}
              {!_.isEmpty(data) && (
                <LogsTable
                  logs={data}
                  logType={logType}
                  hasAppliedFilters={hasAppliedFilters}
                  fetchLogCount={fetchLogCount}
                  logsCount={logsCount}
                  loading={loading}
                  pagination={
                    <CursorPagination
                      nextCursor={nextCursor}
                      previousCursor={previousCursor}
                      pageSize={pageSize}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              )}
            </>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export const InvoiceEmailLogList = ListViewEssentials({
  sdk: async params => emailLogList('invoice', params),
  cursorPagination: true
})(
  LoginRequired(
    withRouter(
      withLogType({ logType: 'invoice', mailTargetName: 'Invoice' })(
        EmailLogList
      )
    )
  )
);

export const AutoCustomEmailLogList = ListViewEssentials({
  sdk: async params => emailLogList('auto', params),
  cursorPagination: true
})(
  LoginRequired(
    withRouter(
      withLogType({ logType: 'auto', mailTargetName: 'Email Template' })(
        EmailLogList
      )
    )
  )
);

export const MailshotEmailLogList = ListViewEssentials({
  sdk: async params => emailLogList('mailshot', params),
  cursorPagination: true
})(
  LoginRequired(
    withRouter(
      withLogType({ logType: 'mailshot', mailTargetName: 'Mailshot' })(
        EmailLogList
      )
    )
  )
);

export const InstantNewsEmailLogList = ListViewEssentials({
  sdk: async params => emailLogList('instant_news', params),
  cursorPagination: true
})(
  LoginRequired(
    withRouter(
      withLogType({ logType: 'instant news', mailTargetName: 'Instant News' })(
        EmailLogList
      )
    )
  )
);
