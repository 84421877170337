import React from 'react';

import { Field, SelectField } from 'components';
const COMMODITIES = [
  'Blank',
  'Agriculture',
  'Energy',
  'Metals / Mining',
  'Petrochemical'
];

class CommoditySelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = COMMODITIES.map(commodity => {
      return {
        key: commodity,
        value: commodity,
        label: commodity
      };
    });

    return (
      <Field
        name={name || 'commodity'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default CommoditySelect;
