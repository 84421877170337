import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { TableFooter, SortableHeader } from 'components';

import { pageUrl } from 'config/routes';
import { formatDateTime } from 'utils';
import { ClientDetail } from 'pages/Client';

const TrackedPriceSeriesOpensTable = ({ items, orderBy, pagination, sort }) => (
  <>
    <Table celled selectable compact={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colspan="5">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Client ID</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <SortableHeader sort={sort} orderBy={orderBy} field="open_at">
            Open at
          </SortableHeader>
          <Table.HeaderCell>Price Display as</Table.HeaderCell>
          <Table.HeaderCell>Request Method</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map(item => (
          <Table.Row>
            <Table.Cell>
              <Link to={pageUrl(ClientDetail, { clientId: item.user.id })}>
                {item.user.id}
              </Link>
            </Table.Cell>
            <Table.Cell>{item.user.email}</Table.Cell>
            <Table.Cell>{formatDateTime(item.open_at)}</Table.Cell>
            <Table.Cell>{item.price.display_as}</Table.Cell>
            <Table.Cell>{item.request_method}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  </>
);

export default TrackedPriceSeriesOpensTable;
