import { UniSelect, AdminSelect } from 'components';
import { POWER_MATERIAL_SLUG } from 'domainConstants';
import { transformValuesForMultiselect } from 'utils/filters';

export const FILTERS = productSlug => [
  [
    {
      name: productSlug === POWER_MATERIAL_SLUG ? 'main_group' : 'group',
      emptyValue: null,
      fieldComponent: UniSelect,
      label: 'Category',
      props: () => ({
        productSlug: productSlug,
        source: 'priceSerieCategoriesForFilters'
      })
    },
    {
      name: 'product',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: UniSelect,
      label: 'Product',
      props: () => ({
        productSlug: productSlug,
        source: 'priceSerieProducts'
      })
    },
    {
      name: 'principal_owner',
      emptyValue: null,
      fieldComponent: AdminSelect,
      transform: value => parseInt(value, 10),
      label: 'Principal owner'
    }
  ],
  [
    {
      name: 'region',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: UniSelect,
      label: 'Region',
      props: () => ({
        source: 'priceSerieRegions'
      })
    },
    {
      name: 'frequency',
      emptyValue: '',
      fieldComponent: UniSelect,
      label: 'Frequency',
      props: () => ({
        source: 'priceSerieFrequencies'
      })
    },
    {
      name: 'price_data_source',
      emptyValue: '',
      fieldComponent: UniSelect,
      label: 'Price Data Source',
      props: () => ({
        source: 'priceSerieDataSources'
      })
    }
  ],
  [
    {
      name: 'currency',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: UniSelect,
      label: 'Currency',
      props: () => ({
        source: 'priceSerieCurrencies'
      })
    },
    {
      name: 'inco_terms',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: UniSelect,
      label: 'INCO Terms',
      props: () => ({
        source: 'priceSerieIncoTerms'
      })
    },
    {
      name: 'article_authors',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: AdminSelect,
      label: 'Authors for Articles',
      props: () => ({
        isMulti: true
      })
    }
  ]
];
