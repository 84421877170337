import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Tab } from 'semantic-ui-react';

import { SUBSCRIPTION, REPORT, COURSE } from 'domainConstants';
import { filterCorporateMasterSubscriptions } from 'pages/Client/utils';

const getProductData = product => {
  const data = {
    id: product.id,
    itemId: product.item.id,
    description: product.item.title,
    type: product.item.item_type,
    quantity: product.quantity,
    price: product.price,
    currency: product.currency,
    status: null,
    statusId: null,
    dateStart: null,
    dateEnd: null,
    fromCorporateMaster: product.fromCorporateMaster
  };

  if (data.type === SUBSCRIPTION) {
    data.status = product.subscription_status.status;
    data.statusId = product.subscription_status.status_id;
    data.dateStart = product.subscription_status.date_start;
    data.dateEnd = product.subscription_status.date_end;
    data.availableToCorporateChildren = product.available_to_corporate_children;
  }

  if (data.type === COURSE) {
    data.status = product.course_status.status;
    data.dateStart = product.course_status.date_start;
    data.dateEnd = product.course_status.dateEnd;
    data.attended = product.course_status.attended;
    data.selfRegistered = product.course_status.self_registered;
    data.promoCode = product.promo_code;
  }

  if (data.type === REPORT) {
    data.status = product.report_status.status;
    data.dateStart = product.report_status.date_start;
    data.dateEnd = product.report_status.dateEnd;
  }

  return data;
};

export const removeProduct = (productId, products) => {
  _.remove(products, function(p) {
    return p.id === productId;
  });
  return products;
};

export const addProduct = (id, type, selectedProducts) => {
  selectedProducts.push({
    id: id,
    type: type
  });
  return selectedProducts;
};

export const addToSelectedProducts = (products, selectedProducts) => {
  return _.uniqBy(selectedProducts.concat(products), 'id');
};

export const removeFromSelectedProducts = (products, selectedProducts) => {
  return _.filter(
    selectedProducts,
    product => !_.includes(_.map(products, 'id'), product.id)
  );
};

export const prepareClientAddInvoiceValues = selectedProducts => {
  let products = {
    subscriptions: [],
    reports: [],
    events: [],
    advertisements: []
  };
  selectedProducts.forEach(product => {
    if (product.type === 'Subscription') {
      products.subscriptions.push(product.id);
    } else if (product.type === 'Report') {
      products.reports.push(product.id);
    } else if (product.type === 'Course') {
      products.events.push(product.id);
    } else if (product.type === 'Advertisement') {
      products.advertisements.push(product.id);
    }
  });

  return products;
};

export const getProducts = client => {
  const ownProducts = client.client_items;
  let cmProducts = [];

  if (client.client_profile.corporate_master_account) {
    cmProducts = filterCorporateMasterSubscriptions(
      client.client_profile.corporate_master_account.client_items,
      client.id
    );
  }

  let products = [
    ...ownProducts.map(product => ({
      ...product,
      fromCorporateMaster: false
    })),
    ...cmProducts.map(product => ({ ...product, fromCorporateMaster: true }))
  ];

  products = _.map(products, getProductData);

  const subscriptions = products.filter(
    product => product.type === 'Subscription'
  );
  let events = products.filter(product => product.type === 'Course');
  events = _.orderBy(events, 'dateStart', 'desc');

  const reports = products.filter(product => product.type === 'Report');
  const advertisements = products.filter(
    product => product.type === 'Advertisement'
  );

  return { subscriptions, events, reports, products, advertisements };
};

const getItemsTitle = items =>
  items && !_.isEmpty(items) ? `(${items.length})` : '';

export const getTabPane = ({ title, items, renderFunction }) => {
  return {
    menuItem: `${title}  ${getItemsTitle(items)}`,
    render: () => <Tab.Pane>{renderFunction(items)}</Tab.Pane>
  };
};

export const prepareParams = data => {
  const pipelineItem = _.first(data);

  return {
    forecastedMonth: parseInt(moment(pipelineItem.forecasted_date).format('M')),
    forecastedYear: parseInt(
      moment(pipelineItem.forecasted_date).format('YYYY')
    ),
    defaultOnEmpty: true
  };
};
