import _ from 'lodash';
import React from 'react';

import { Segment } from 'semantic-ui-react';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import {
  Page,
  Header,
  Flex,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  NoDataMessage,
  Filters as SharedFilters,
  Loader
} from 'components';

import { TrackedPriceSeriesOpensTable, TopPriceTable } from './components';

import { trackedPriceSeriesOpens } from './sdk';

import { FILTERS } from './utils';

const TrackedPriceSeriesOpens = ({
  user,
  data,
  limit,
  count,
  page,
  changePage,
  changeRowsPerPage,
  filters,
  filterBy,
  clear,
  sort,
  orderBy,
  isFetching,
  response
}) => {
  const mostOpenedPrices = _.get(response, 'most_opened_prices', []);
  const mostDownloadedPrices = _.get(response, 'most_downloaded_prices', []);

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content>
          <Flex spaceBetween>
            <Header>Tracked Price Series Opens</Header>
          </Flex>
          <>
            <SharedFilters
              filters={filters}
              filterBy={filterBy}
              clearFilters={clear}
              count={count}
              layout={FILTERS}
            />
            {isFetching && (
              <Segment>
                <Loader />
              </Segment>
            )}
            {_.isEmpty(data) && !isFetching && <NoDataMessage />}
            {!_.isEmpty(data) && !isFetching && (
              <React.Fragment>
                <TrackedPriceSeriesOpensTable
                  items={data}
                  sort={sort}
                  orderBy={orderBy}
                  isFetching={isFetching}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
                <Flex flexStart={true} alignStart={true}>
                  <TopPriceTable
                    priceData={mostOpenedPrices}
                    filters={filters}
                    filterBy={filterBy}
                    actionType="Open"
                  />
                  <TopPriceTable
                    priceData={mostDownloadedPrices}
                    filters={filters}
                    filterBy={filterBy}
                    actionType="Download"
                  />
                </Flex>
              </React.Fragment>
            )}
          </>
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default ListViewEssentials({
  sdk: trackedPriceSeriesOpens
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_CLIENTS])(TrackedPriceSeriesOpens)
  )
);
