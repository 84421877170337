import _ from 'lodash';

import { Button, Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { formatDateTime, openInNewTab } from 'utils';

import { YesNo, TableFooter, Flex } from 'components';

import { BACKEND_URL } from 'sdk/urls';
import EmailLogStatisticModal from 'components/EmailLogStatisticModal';
import { PLAIN_TEXT } from 'pages/EmailLog/constants';

const logsWithPreview = ['mailshot', 'auto'];

const generatePreviewUrl = (log, logType) => {
  const clientId = _.get(log, 'client.id', '');

  const mapping = {
    mailshot: `${BACKEND_URL}/emails/mailshot/view/${log.mailshot_id}/${clientId}/${log.email_type}/`,
    auto: `${BACKEND_URL}/emails/email_template/preview/${log.template_id}/${log.email_type}/?client_id=${clientId}`
  };

  return mapping[logType];
};

const LogsTable = ({
  logs,
  pagination,
  logType,
  hasAppliedFilters,
  fetchLogCount,
  logsCount,
  loading
}) => (
  <Table celled selectable compact={true}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="12">
          {(logsCount || !hasAppliedFilters) && pagination}
          {!logsCount && hasAppliedFilters && (
            <Flex spaceBetween>
              <Button primary onClick={fetchLogCount} disabled={loading}>
                {loading ? 'Loading...' : 'Count'}
              </Button>
              {pagination}
            </Flex>
          )}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>Client id</Table.HeaderCell>
        <Table.HeaderCell>Send date</Table.HeaderCell>
        <Table.HeaderCell>From Name</Table.HeaderCell>
        <Table.HeaderCell>Company</Table.HeaderCell>
        <Table.HeaderCell>Recipient email</Table.HeaderCell>
        <Table.HeaderCell>Phone</Table.HeaderCell>
        <Table.HeaderCell>Subject</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Opened</Table.HeaderCell>
        {logsWithPreview.includes(logType) && (
          <Table.HeaderCell>View</Table.HeaderCell>
        )}
        <Table.HeaderCell># of Opens</Table.HeaderCell>
        <Table.HeaderCell>Statistic</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {logs.map(log => (
        <Table.Row key={log.id}>
          <Table.Cell width={1}>
            {log.client && (
              <Link
                to={pageUrl(ClientDetail, {
                  clientId: log.client.id
                })}>
                {log.client.id}
              </Link>
            )}
            {!log.client && 'N/A'}
          </Table.Cell>
          <Table.Cell>{formatDateTime(log.sent_date)}</Table.Cell>
          <Table.Cell>{log.from_name}</Table.Cell>
          <Table.Cell>{_.get(log, 'client.company', 'N/A')}</Table.Cell>
          <Table.Cell>{log.email}</Table.Cell>
          <Table.Cell width={1}>{_.get(log, 'client.phone')}</Table.Cell>
          <Table.Cell width={4}>{log.subject}</Table.Cell>
          <Table.Cell width={1}>{log.email_type}</Table.Cell>
          <Table.Cell textAlign="center">
            {log.email_type === PLAIN_TEXT ? (
              'N/A'
            ) : (
              <YesNo value={log.is_opened} />
            )}
          </Table.Cell>
          {logsWithPreview.includes(logType) && (
            <Table.Cell>
              <Link
                onClick={() => {
                  openInNewTab(generatePreviewUrl(log, logType));
                }}>
                Web View
              </Link>
            </Table.Cell>
          )}
          <Table.Cell width={1}>
            {log.email_type === PLAIN_TEXT ? 'N/A' : log.statistic_logs_count}
          </Table.Cell>
          <Table.Cell width={1}>
            {log.email_type === PLAIN_TEXT ? (
              'N/A'
            ) : (
              <EmailLogStatisticModal logType={logType} log={log} />
            )}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default LogsTable;
