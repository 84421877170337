import _ from 'lodash';

import React from 'react';

import { withRouter } from 'react-router-dom';

import { Formik } from 'formik';

import { Table, Button, Checkbox } from 'semantic-ui-react';

import {
  Field,
  SubscriptionStatusSelect,
  EventStatusSelect,
  ReportStatusSelect,
  DateField,
  UnsavedFormPrompt,
  CheckboxField,
  OpenModalButton,
  ConfirmationModal,
  YesNo
} from 'components';

import { hasPermissions, PERMISSIONS } from 'permissions';

import {
  SUBSCRIPTION,
  REPORT,
  COURSE,
  ADVERTISEMENT,
  LATEST_ISSUE_TITLES
} from 'domainConstants';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { formatDateForApi } from 'utils';
import { notifySuccess } from 'utils/notifications';

import { pageUrl } from 'config/routes';
import { ClientDetail } from 'pages';
import EventPromoCodeSelect from 'components/fields/EventPromoCodeSelect';

import { LatestIssueModal } from 'pages/Client/Detail/components/Products/components';
import { ManageSubscriptionChildrenModal } from 'pages/Client/Detail/components/Products/components';

import { isSelected, productValidationSchema } from './utils';
import { clientUpdateProduct, clientRemoveProduct } from './sdk';
import { EVENT_STATUSES_TO_CONFIRM } from './constants';

class ProductItemForm extends React.Component {
  state = {
    isUpdateConfirmationModalOpen: false,
    isRemoveConfirmationModalOpen: false
  };

  openUpdateConfirmationModal = () =>
    this.setState({
      isRemoveConfirmationModalOpen: false,
      isUpdateConfirmationModalOpen: true
    });

  openRemoveConfirmationModal = () =>
    this.setState({
      isUpdateConfirmationModalOpen: false,
      isRemoveConfirmationModalOpen: true
    });

  closeConfirmationModal = () => {
    this.setState({
      isUpdateConfirmationModalOpen: false,
      isRemoveConfirmationModalOpen: false
    });
  };

  goToCmAccount = () => {
    const { client } = this.props;

    const clientId = client.client_profile.corporate_master_account.id;
    return pageUrl(ClientDetail, { clientId });
  };

  handleSubmit = async (values, actions) => {
    const { product, client, fetchClient } = this.props;
    const { type } = product;

    const { setSubmitting, setFieldError } = actions;

    const data = {
      clientItem: product.id,
      dateStart: values.dateStart,
      status: values.status
    };

    if (type === SUBSCRIPTION) {
      data.dateEnd = values.dateEnd || null;
    }
    if (type === COURSE) {
      data.attended = values.attended;
      data.promoCode = values.promoCode;
    }

    setSubmitting(true);
    const { success, errors } = await clientUpdateProduct(
      client.id,
      type,
      prepareFormData(data)
    );
    setSubmitting(false);

    if (success) {
      notifySuccess(`${product.description} updated.`);
      fetchClient();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  getStatusSelect = product => {
    const { type } = product;

    const { fromCorporateMaster } = product;

    const props = {
      name: 'status',
      isClearable: false,
      disabled: fromCorporateMaster
    };
    let Component = null;

    if (type === SUBSCRIPTION) {
      Component = SubscriptionStatusSelect;
    }

    if (type === COURSE) {
      Component = EventStatusSelect;
    }

    if (type === REPORT) {
      Component = ReportStatusSelect;
    }

    if (!Component) {
      return 'N/A';
    }

    return <Component {...props} />;
  };

  hasDateEnd = product => {
    const { type } = product;

    return type === SUBSCRIPTION;
  };

  removeProductItem = async () => {
    const { product, client, fetchClient } = this.props;

    const postData = {
      clientItem: product.id
    };

    const { success } = await clientRemoveProduct(
      client.id,
      prepareFormData(postData)
    );

    if (success) {
      notifySuccess('Product item deleted.');
      fetchClient();
    }
  };

  isAdvertisement = product => {
    const { type } = product;

    return type === ADVERTISEMENT;
  };

  isCourse = product => {
    const { type } = product;

    return type === COURSE;
  };

  getCourseActions = (
    product,
    values,
    handleSubmit,
    isSubmitting,
    canChangeProducts,
    canRemoveProducts
  ) => {
    let updateButtonProps = {
      onClick: handleSubmit,
      type: 'submit'
    };
    let removeButtonProps = {
      onClick: this.removeProductItem,
      type: 'submit'
    };

    if (this.isCourse(product)) {
      const currentStatusValue = values.status;

      if (
        product.selfRegistered &&
        currentStatusValue !== product.status &&
        _.includes(EVENT_STATUSES_TO_CONFIRM, currentStatusValue) &&
        !_.includes(EVENT_STATUSES_TO_CONFIRM, product.status)
      ) {
        updateButtonProps = {
          type: 'button',
          onClick: () => this.openUpdateConfirmationModal()
        };
      }

      if (product.selfRegistered) {
        removeButtonProps = {
          type: 'button',
          onClick: () => this.openRemoveConfirmationModal()
        };
      }
    }

    return (
      <>
        {!this.isAdvertisement(product) && (
          <Button
            size="small"
            color="blue"
            data-testid="product-update-btn"
            disabled={isSubmitting || !canChangeProducts}
            {...updateButtonProps}>
            Update
          </Button>
        )}

        <Button
          basic
          size="small"
          disabled={isSubmitting || !canRemoveProducts}
          {...removeButtonProps}>
          Remove
        </Button>
      </>
    );
  };

  render() {
    const {
      product,
      selectedProducts,
      toggleProductSelect,
      user,
      client,
      fetchClient
    } = this.props;

    const { fromCorporateMaster } = product;
    const isCorporateMaster = client.client_profile.is_corporate_master_profile;

    const canChangeProducts = hasPermissions(user, [
      PERMISSIONS.CHANGE_CLIENTS,
      PERMISSIONS.CHANGE_PRODUCTS
    ]);

    const canRemoveProducts = hasPermissions(user, [
      PERMISSIONS.CHANGE_CLIENTS,
      PERMISSIONS.DELETE_PRODUCTS
    ]);

    const canSendLatestIssue = hasPermissions(user, [
      PERMISSIONS.SEND_EMAILS_TO_CLIENTS
    ]);

    const initialValues = {
      status: product.statusId || product.status,
      dateStart: product.dateStart && formatDateForApi(product.dateStart),
      dateEnd: product.dateEnd && formatDateForApi(product.dateEnd),
      attended: product.attended || false,
      promoCode: product.promoCode || null
    };

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={productValidationSchema}>
        {({
          handleSubmit,
          isSubmitting,
          dirty,
          values,
          setSubmitting,
          setFieldError,
          setFieldValue
        }) => (
          <>
            <UnsavedFormPrompt
              when={dirty}
              formName={`${product.description} product form`}
            />
            <Table.Row>
              <Table.Cell
                style={{
                  width: '50px',
                  padding: '16.5px',
                  textAlign: 'center'
                }}>
                {!fromCorporateMaster && (
                  <Checkbox
                    onChange={(event, data) =>
                      toggleProductSelect(data, product.itemId, product.type)
                    }
                    checked={isSelected(product.itemId, selectedProducts)}
                  />
                )}
              </Table.Cell>
              <Table.Cell width={3} data-testid="product-description">
                {product.description}
                {fromCorporateMaster ? ' (CM)' : ''}
              </Table.Cell>
              <Table.Cell width={3} data-testid="product-status-select">
                {this.getStatusSelect(product)}
              </Table.Cell>
              <Table.Cell width={2} data-testid="product-start-date">
                {!this.isAdvertisement(product) && (
                  <Field
                    name="dateStart"
                    component={DateField}
                    disabled={fromCorporateMaster || !canChangeProducts}
                  />
                )}
              </Table.Cell>
              <Table.Cell width={2} data-testid="product-end-date">
                {this.hasDateEnd(product) && (
                  <Field
                    name="dateEnd"
                    component={DateField}
                    disabled={fromCorporateMaster || !canChangeProducts}
                  />
                )}
              </Table.Cell>
              {product.type === COURSE && (
                <>
                  <Table.Cell width={1} textAlign="center">
                    <Field
                      component={CheckboxField}
                      name="attended"
                      disabled={fromCorporateMaster || !canChangeProducts}
                    />
                  </Table.Cell>
                  <Table.Cell width={1} textAlign="center">
                    <YesNo value={product.selfRegistered} />
                  </Table.Cell>
                  <Table.Cell width={2}>
                    <EventPromoCodeSelect courseItemId={product.itemId} />
                  </Table.Cell>
                </>
              )}
              <Table.Cell
                textAlign="center"
                width={this.isCourse(product) ? 3 : 5}>
                {!fromCorporateMaster && (
                  <>
                    {this.getCourseActions(
                      product,
                      values,
                      handleSubmit,
                      isSubmitting,
                      canChangeProducts,
                      canRemoveProducts
                    )}
                  </>
                )}

                {product.type === SUBSCRIPTION &&
                  LATEST_ISSUE_TITLES.includes(product.description) && (
                    <OpenModalButton
                      basic
                      size="small"
                      color="unset"
                      disabled={!canSendLatestIssue}
                      modal={{
                        header: 'Send latest issue',
                        render: props => (
                          <LatestIssueModal
                            client={client}
                            product={product}
                            {...props}
                          />
                        )
                      }}>
                      Send latest issue
                    </OpenModalButton>
                  )}
                {product.type === SUBSCRIPTION && isCorporateMaster && (
                  <OpenModalButton
                    basic
                    size="small"
                    color="unset"
                    modal={{
                      header: 'Manage children availability',
                      render: props => (
                        <ManageSubscriptionChildrenModal
                          user={user}
                          client={client}
                          clientItem={product}
                          fetchClient={fetchClient}
                          {...props}
                        />
                      )
                    }}>
                    Children
                  </OpenModalButton>
                )}
                {fromCorporateMaster && (
                  <a href={this.goToCmAccount()}>
                    <Button basic size="small">
                      Go to CM account
                    </Button>
                  </a>
                )}
              </Table.Cell>
            </Table.Row>
            {this.state.isUpdateConfirmationModalOpen && (
              <ConfirmationModal
                title={`Change registration status of client to '${values.status}'?`}
                message={
                  'The client has already registered for this event via the website. ' +
                  'By changing their registration status, the client would be able to register again'
                }
                open={this.state.isUpdateConfirmationModalOpen}
                onClose={this.closeConfirmationModal}
                handleConfirmation={() =>
                  this.handleSubmit(values, {
                    setSubmitting,
                    setFieldError
                  })
                }
                handleDecline={() =>
                  // revert status to initial one
                  setFieldValue('status', product.status)
                }
              />
            )}
            {this.state.isRemoveConfirmationModalOpen && (
              <ConfirmationModal
                title="Removing the event product for this client?"
                message={
                  'The client has already registered for this event via the website. ' +
                  'By removing the event product, the client would be able to register again.'
                }
                open={this.state.isRemoveConfirmationModalOpen}
                onClose={this.closeConfirmationModal}
                handleConfirmation={this.removeProductItem}
                handleDecline={this.closeConfirmationModal}
              />
            )}
          </>
        )}
      </Formik>
    );
  }
}

export default withRouter(ProductItemForm);
