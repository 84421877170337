import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { pageFactory } from 'utils/pages';

import {
  NotFound,
  Login,
  RedirectToNotFound,
  RedirectToMedia,
  Dashboard,
  ClientList,
  ClientSuppressedList,
  EmailSuppressedList,
  InvoiceList,
  ClientReminderList,
  ClientReminderDetail,
  ClientCreate,
  ClientCreateCorporateChild,
  MailshotList,
  MailshotCreate,
  MailshotDetail,
  MailshotClients,
  MailshotSelectedClients,
  EmailTemplateList,
  EmailTemplateCreate,
  EmailTemplateDetail,
  ClientDetail,
  InvoiceDetail,
  ClientReminderCreate,
  CourseList,
  CourseCreate,
  CourseDetail,
  MarketDataReportList,
  MarketDataReportDetail,
  MarketDataReportCreate,
  OrderList,
  OrderDetail,
  EmailDashboard,
  SentimentHeatMapDetail,
  SubscriptionList,
  SubscriptionDetail,
  BannerList,
  BannerDetail,
  BannerCreate,
  PageList,
  PageDetail,
  PollList,
  PollDetail,
  PollCreate,
  TrainerList,
  TrainerCreate,
  TrainerDetail,
  ClientProfileLogList,
  VideoGalleryList,
  VideoGalleryCreate,
  VideoDetail,
  WeeklySteelOpenCount,
  WeeklySeasmOpenCount,
  DailyNewsletterList,
  DailyNewsletterCreate,
  DailyNewsletterDetail,
  WeeklySteelList,
  WeeklySteelDetail,
  WeeklySteelCreate,
  WeeklyPowerMaterialsList,
  WeeklyPowerMaterialsCreate,
  WeeklyPowerMaterialsDetail,
  ChinaSteelList,
  ChinaSteelReportDetail,
  ChinaSteelReportCreate,
  TradeExcelList,
  TradeExcelCreate,
  TradeExcelDetail,
  CurrencyRateList,
  StaffList,
  StaffDetail,
  StaffCreate,
  LoginAttemptList,
  GroupsAndPermissionsList,
  GroupsAndPermissionsCreate,
  GroupsAndPermissionsDetail,
  ProductList,
  ProductCreate,
  ProductDetail,
  CountryList,
  CountryDetail,
  CountryCreate,
  RedirectToDashboard,
  NewsletterReceiversList,
  EventsDashboard,
  InvoiceEmailLogList,
  AutoCustomEmailLogList,
  MailshotEmailLogList,
  InstantNewsEmailLogList,
  DailyNewsletterEmailLogListV2,
  WeeklySteelEmailLogList,
  PriceSpreadList,
  PriceSpreadDetail,
  PriceSpreadCreate,
  RenewalClientListV2,
  RenewalsDashboard,
  LinkedClientsList,
  PermanentPricesDetail,
  GlossaryCategoryList,
  GlossaryCategoryCreate,
  GlossaryCategoryDetail,
  GlossaryTermList,
  GlossaryTermCreate,
  GlossaryTermDetail,
  RegistrationAttemptList,
  ClientCreateFromRegistrationAttempt,
  GeneralPipelinesDashboard,
  PersonalPipelineDashboard,
  SalesManagerPipelineDashboard,
  InvoicesDashboard,
  ClientLoginInformationList,
  CorporateRssFeedAccessLog,
  ClientLoginsList,
  TrackedPriceSeriesOpens,
  PriceSpreadMovementList,
  ExchangeRates,
  BudgetDashboard,
  TrackingComments,
  TrackingSubscriptionTrialEvents,
  TrackingUnsubscriptionEvents,
  TrackingSubscriptionEvents,
  TrackingMarketReportEvents,
  TrackingCoursesEvents,
  TrackingFAQSubmissions,
  SEASMEditionDetail,
  SEASMEdtionCreate,
  SEASMEditionList,
  WebArticleList,
  WebArticleCreate,
  WebArticleDetail,
  PriceSerieList,
  PriceSerieCreate,
  PriceSerieDetail,
  PriceSerieMissingDataList,
  PriceSerieMovementList,
  PriceSerieUpdate,
  PriceSeriePublish,
  NewsletterList,
  NewsletterCreate,
  NewsletterDetail,
  NewsletterSetup,
  ContentSection,
  RecipientGroups,
  NewsletterPermanentPrices,
  NewsletterReceiversListV2,
  TrackingFeedback,
  FeedbackList,
  SalesTerritoryList,
  FaqList,
  FaqCreate,
  FaqDetail,
  FaqCategoryList,
  FaqCategoryCreate,
  FaqCategoryDetail,
  PriceSpreadV2List,
  PriceSpreadV2Create,
  PriceSpreadV2Detail,
  PriceSpreadV2Movement,
  CompanyV2Create,
  CompanyV2Detail,
  CompanyV2List,
  ArticleChangesList,
  ArticleChangesAnalyticsList,
  Maintenance,
  OnlineUsersList,
  PriceIndexList,
  PriceIndexCreate,
  PriceIndexDetail,
  UnitConversionRateList,
  PriceIndexChart,
  TrackedArticlesList,
  MailshotGroupList,
  MailshotGroupCreate,
  MailshotGroupDetail,
  WeeklyPowerMaterialsEmailLogList,
  WeeklyPowerMaterialsOpenCount
} from 'pages';

// import FormikFieldsGuide from 'pages/FormikFieldsGuide';

// import FormGuide from 'pages/FormGuide';

const crmPages = [
  {
    component: ClientList,
    parent: null,
    path: '/crm/clients',
    title: 'Clients'
  },
  {
    component: ClientDetail,
    parent: ClientList,
    path: '/crm/clients/edit/:clientId',
    title: 'Client Detail'
  },
  {
    component: ClientCreate,
    parent: ClientList,
    path: '/crm/clients/create',
    title: 'Create Client'
  },
  {
    component: ClientCreateFromRegistrationAttempt,
    parent: ClientList,
    path: '/crm/clients/create/:registrationAttemptId',
    title: 'Create Client'
  },
  {
    component: LinkedClientsList,
    parent: null,
    path: '/crm/clients/linked',
    title: 'Linked Clients'
  },
  {
    component: ClientCreateCorporateChild,
    parent: ClientList,
    path: '/crm/clients/:corporateMasterId/create-corporate-child',
    title: 'Create Client'
  },
  {
    component: InvoiceList,
    parent: null,
    path: '/crm/invoices',
    title: 'Invoices'
  },
  {
    component: InvoiceDetail,
    parent: InvoiceList,
    path: '/crm/invoices/edit/:invoiceId',
    title: 'Invoice Detail'
  },
  {
    component: ClientReminderList,
    parent: null,
    path: '/crm/client-reminders',
    title: 'Client Reminders'
  },
  {
    component: ClientReminderCreate,
    parent: ClientReminderList,
    path: '/crm/client-reminders/create',
    title: 'Create'
  },
  {
    component: ClientReminderDetail,
    parent: ClientReminderList,
    path: '/crm/client-reminders/:clientReminderId',
    title: 'Client Reminder Detail'
  },
  {
    component: OrderList,
    parent: null,
    path: '/crm/orders',
    title: 'Orders'
  },
  {
    component: OrderDetail,
    parent: OrderList,
    path: '/crm/orders/:orderId',
    title: 'Order Detail'
  },
  {
    component: RenewalClientListV2,
    parent: ClientList,
    path: '/crm/renewal-clients/',
    title: 'Renewals Sheet'
  },
  {
    component: ClientSuppressedList,
    parent: ClientList,
    path: '/crm/suppressed-clients',
    title: 'Suppressed'
  },
  {
    component: EmailSuppressedList,
    parent: ClientSuppressedList,
    path: '/crm/suppressed-emails',
    title: 'Suppressed'
  },
  {
    component: ClientProfileLogList,
    parent: null,
    path: '/crm/client-profile-logs',
    title: 'Client Profile Logs'
  },
  {
    component: RegistrationAttemptList,
    parent: null,
    path: '/crm/registration-attempts',
    title: 'Registration Attempts'
  },
  {
    component: CorporateRssFeedAccessLog,
    parent: null,
    path: '/crm/rss-access-log',
    title: 'Corporate RSS Access Log'
  }
];

const dashboardPages = [
  {
    component: Dashboard,
    parent: null,
    path: '/dashboard',
    title: 'Dashboard'
  },
  {
    component: EmailDashboard,
    parent: Dashboard,
    path: '/email-dashboard',
    title: 'Email Dashboard'
  },
  {
    component: EventsDashboard,
    parent: null,
    path: '/events-dashboard',
    title: 'Events Dashboard'
  },
  {
    component: RenewalsDashboard,
    parent: null,
    path: '/renewals-dashboard',
    title: 'Renewals Dashboard'
  },
  // TODO: disable for prod deploy
  {
    component: GeneralPipelinesDashboard,
    parent: null,
    path: '/pipeline-dashboard',
    title: 'General Pipeline Dashboard'
  },
  {
    component: SalesManagerPipelineDashboard,
    parent: null,
    path: '/personal-pipeline-dashboard/:managerId',
    title: 'Personal Pipeline Dashboard'
  },
  {
    component: PersonalPipelineDashboard,
    parent: null,
    path: '/personal-pipeline-dashboard',
    title: 'Personal Pipeline Dashboard'
  },
  {
    component: InvoicesDashboard,
    parent: null,
    path: '/incomesheet-dashboard',
    title: 'Invoices Dashboard'
  },
  {
    component: BudgetDashboard,
    parent: null,
    path: '/budget-dashboard',
    title: 'Budget Dashboard'
  },
  {
    component: TrackingComments,
    parent: null,
    path: '/forms-dashboard',
    title: 'Forms Dashboard'
  },
  {
    component: TrackingFeedback,
    parent: null,
    path: '/forms-dashboard/feedback',
    title: 'Forms Dashboard'
  },
  {
    component: TrackingSubscriptionTrialEvents,
    parent: null,
    path: '/forms-dashboard/trial-requests',
    title: 'Forms Dashboard'
  },
  {
    component: TrackingUnsubscriptionEvents,
    parent: null,
    path: '/forms-dashboard/unsubscription-request',
    title: 'Forms Dashboard'
  },
  {
    component: TrackingSubscriptionEvents,
    parent: null,
    path: '/forms-dashboard/subscription-request',
    title: 'Forms Dashboard'
  },
  {
    component: TrackingMarketReportEvents,
    parent: null,
    path: '/forms-dashboard/market-report',
    title: 'Forms Dashboard'
  },
  {
    component: TrackingCoursesEvents,
    parent: null,
    path: '/forms-dashboard/courses',
    title: 'Forms Dashboard'
  },
  {
    component: TrackingFAQSubmissions,
    parent: null,
    path: '/forms-dashboard/faq-submissions',
    title: 'Forms Dashboard'
  }
];

const cmsPages = [
  {
    component: SentimentHeatMapDetail,
    parent: null,
    path: '/cms/sentiment-heat-map',
    title: 'Sentiment Heat Map'
  },
  {
    component: SubscriptionList,
    parent: null,
    path: '/cms/subscriptions',
    title: 'Subscriptions'
  },
  {
    component: SubscriptionDetail,
    parent: SubscriptionList,
    path: '/cms/subscriptions/edit/:subscriptionId',
    title: 'Detail'
  },
  {
    component: BannerList,
    parent: null,
    path: '/cms/banners',
    title: 'Banners'
  },
  {
    component: BannerDetail,
    parent: BannerList,
    path: '/cms/banners/edit/:bannerId',
    title: 'Detail'
  },
  {
    component: BannerCreate,
    parent: BannerList,
    path: '/cms/banners/create',
    title: 'Create'
  },
  {
    component: FeedbackList,
    parent: null,
    path: '/cms/feedback',
    title: 'Feedback'
  },
  {
    component: PageList,
    parent: null,
    path: '/cms/pages',
    title: 'Pages'
  },
  {
    component: PageDetail,
    parent: PageList,
    path: '/cms/pages/edit/:pageId',
    title: 'Detail'
  },
  {
    component: PollList,
    parent: null,
    path: '/cms/polls',
    title: 'Polls'
  },
  {
    component: PollDetail,
    parent: PollList,
    path: '/cms/polls/edit/:pollId',
    title: 'Detail'
  },
  {
    component: PollCreate,
    parent: PollList,
    path: '/cms/polls/create',
    title: 'Create'
  },
  {
    component: TrainerList,
    parent: null,
    path: '/cms/trainers',
    title: 'Speakers'
  },
  {
    component: TrainerDetail,
    parent: TrainerList,
    path: '/cms/trainers/edit/:trainerId',
    title: 'Detail'
  },
  {
    component: TrainerCreate,
    parent: TrainerList,
    path: '/cms/trainers/create',
    title: 'Create'
  },
  {
    component: VideoGalleryList,
    parent: null,
    path: '/cms/videos',
    title: 'Videos'
  },
  {
    component: VideoDetail,
    parent: VideoGalleryList,
    path: '/cms/videos/edit/:videoId',
    title: 'Detail'
  },
  {
    component: VideoGalleryCreate,
    parent: VideoGalleryList,
    path: '/cms/videos/create',
    title: 'Create'
  },
  {
    component: StaffList,
    parent: null,
    path: '/cms/staff-members',
    title: 'Staff Members'
  },
  {
    component: StaffDetail,
    parent: StaffList,
    path: '/cms/staff-members/edit/:userId',
    title: 'Detail'
  },
  {
    component: StaffCreate,
    parent: StaffList,
    path: '/cms/staff-members/create',
    title: 'Create'
  },
  {
    component: ProductList,
    parent: null,
    path: '/cms/price-series-products',
    title: 'Price Series Products'
  },
  {
    component: ProductCreate,
    parent: ProductList,
    path: '/cms/price-series-products/create',
    title: 'Create'
  },
  {
    component: ProductDetail,
    parent: ProductList,
    path: '/cms/price-series-products/edit/:productId',
    title: 'Detail'
  },
  {
    component: CountryList,
    parent: null,
    path: '/cms/countries',
    title: 'Countries'
  },
  {
    component: CountryCreate,
    parent: CountryList,
    path: '/cms/countries/create',
    title: 'Create'
  },
  {
    component: CountryDetail,
    parent: CountryList,
    path: '/cms/countries/edit/:countryId',
    title: 'Detail'
  },
  {
    component: GlossaryCategoryList,
    parent: null,
    path: '/cms/glossary-categories',
    title: 'Glossary Categories'
  },
  {
    component: GlossaryCategoryCreate,
    parent: GlossaryCategoryList,
    path: '/cms/glossary-categories/create',
    title: 'Create Glossary Category'
  },
  {
    component: GlossaryCategoryDetail,
    parent: GlossaryCategoryList,
    path: '/cms/glossary-categories/:glossaryCategoryId',
    title: 'Detail'
  },
  {
    component: GlossaryTermList,
    parent: null,
    path: '/cms/glossary-terms',
    title: 'Glossary Terms'
  },
  {
    component: GlossaryTermCreate,
    parent: null,
    path: '/cms/glossary-terms/create',
    title: 'Create'
  },
  {
    component: GlossaryTermDetail,
    parent: GlossaryTermList,
    path: '/cms/glossary-terms/:glossaryTermId',
    title: 'Update'
  },
  {
    component: SalesTerritoryList,
    parent: null,
    path: '/cms/sales-territories',
    title: 'Sales Territories'
  },
  {
    component: FaqList,
    parent: null,
    path: '/cms/faqs',
    title: 'FAQs'
  },
  {
    component: FaqCreate,
    parent: null,
    path: '/cms/faqs/create',
    title: 'Create'
  },
  {
    component: FaqDetail,
    parent: FaqList,
    path: '/cms/faqs/:faqId',
    title: 'Update'
  },
  {
    component: FaqCategoryList,
    parent: null,
    path: '/cms/faq-categories',
    title: 'FAQ Categories'
  },
  {
    component: FaqCategoryCreate,
    parent: FaqCategoryList,
    path: '/cms/faq-categories/create',
    title: 'Create FAQ Category'
  },
  {
    component: FaqCategoryDetail,
    parent: FaqCategoryList,
    path: '/cms/faq-categories/:faqCategoryId',
    title: 'Detail'
  }
];

const emailPages = [
  {
    component: MailshotList,
    parent: null,
    path: '/emails/mailshots',
    title: 'Mailshots'
  },
  {
    component: MailshotDetail,
    parent: MailshotList,
    path: '/emails/mailshots/edit/:mailshotId',
    title: 'Detail'
  },
  {
    component: MailshotCreate,
    parent: MailshotList,
    path: '/emails/mailshots/create',
    title: 'Create'
  },
  {
    component: MailshotClients,
    parent: MailshotDetail,
    path: '/emails/mailshots/edit/:mailshotId/clients',
    title: 'Clients'
  },
  {
    component: MailshotSelectedClients,
    parent: MailshotDetail,
    path: '/emails/mailshots/edit/:mailshotId/selected-clients',
    title: 'Selected clients'
  },
  {
    component: EmailTemplateList,
    parent: null,
    path: '/emails/email-templates',
    title: 'Email templates'
  },
  {
    component: EmailTemplateDetail,
    parent: EmailTemplateList,
    path: '/emails/email-templates/edit/:emailTemplateId',
    title: 'Detail'
  },
  {
    component: EmailTemplateCreate,
    parent: EmailTemplateList,
    path: '/emails/email-templates/create',
    title: 'Create'
  },
  {
    component: InvoiceEmailLogList,
    parent: null,
    path: '/emails/logs/invoice',
    title: 'Invoice Email Logs'
  },
  {
    component: AutoCustomEmailLogList,
    parent: null,
    path: '/emails/logs/auto',
    title: 'Auto Email Logs'
  },
  {
    component: MailshotEmailLogList,
    parent: null,
    path: '/emails/logs/mailshot',
    title: 'Mailshot Email Logs'
  },
  {
    component: InstantNewsEmailLogList,
    parent: null,
    path: '/emails/logs/instant-news',
    title: 'Instant News Email Logs'
  },
  {
    component: WeeklySteelEmailLogList,
    parent: null,
    path: '/emails/logs/weekly-steel',
    title: 'Weekly Steel Email Logs'
  },
  {
    component: WeeklyPowerMaterialsEmailLogList,
    parent: null,
    path: '/emails/logs/weekly-power-materials',
    title: 'Weekly Power Materials Email Logs'
  },
  {
    component: WeeklySteelOpenCount,
    parent: null,
    path: '/emails/weekly-steel/open-count',
    title: 'Weekly Steel Open Count'
  },
  {
    component: WeeklySeasmOpenCount,
    parent: null,
    path: '/emails/weekly-seasm/open-count',
    title: 'Weekly SEASM Open Count'
  },
  {
    component: WeeklyPowerMaterialsOpenCount,
    parent: null,
    path: '/emails/weekly-power-materials/open-count',
    title: 'Weekly Power Materials Open Count'
  },
  {
    component: DailyNewsletterEmailLogListV2,
    parent: null,
    path: '/emails/logs/:productSlug',
    title: 'Daily Newsletter Email Logs'
  },
  {
    component: MailshotGroupList,
    parent: null,
    path: '/emails/mailshot-groups',
    title: 'Mailshot Groups'
  },
  {
    component: MailshotGroupCreate,
    parent: MailshotGroupList,
    path: '/emails/mailshot-groups/create',
    title: 'Create Mailshot Group'
  },
  {
    component: MailshotGroupDetail,
    parent: MailshotGroupList,
    path: '/emails/mailshot-groups/edit/:mailshotGroupId',
    title: 'Mailshot Group Detail'
  }
];

const editorialsPages = [
  {
    component: DailyNewsletterList,
    parent: null,
    path: '/editorials/newsletters',
    title: 'Daily Newsletter List'
  },
  {
    component: DailyNewsletterCreate,
    parent: DailyNewsletterList,
    path: '/editorials/newsletters/create',
    title: 'Daily Newsletter Create'
  },
  {
    component: DailyNewsletterDetail,
    parent: DailyNewsletterList,
    path: '/editorials/newsletters/edit/:dailyNewsletterId',
    title: 'Detail'
  },
  {
    component: WeeklySteelList,
    parent: null,
    path: '/editorials/weekly-steel',
    title: 'Weekly Steel'
  },
  {
    component: WeeklySteelCreate,
    parent: WeeklySteelList,
    path: '/editorials/weekly-steel/create',
    title: 'Create'
  },
  {
    component: WeeklySteelDetail,
    parent: WeeklySteelList,
    path: '/editorials/weekly-steel/edit/:id',
    title: 'Detail'
  },
  {
    component: WeeklyPowerMaterialsList,
    parent: null,
    path: '/editorials/weekly-power-materials',
    title: 'Weekly Power Materials'
  },
  {
    component: WeeklyPowerMaterialsCreate,
    parent: WeeklyPowerMaterialsList,
    path: '/editorials/weekly-power-materials/create',
    title: 'Create'
  },
  {
    component: WeeklyPowerMaterialsDetail,
    parent: WeeklyPowerMaterialsList,
    path: '/editorials/weekly-power-materials/edit/:id',
    title: 'Detail'
  },
  {
    component: ChinaSteelList,
    parent: null,
    path: '/editorials/china-steel',
    title: 'China Steel'
  },
  {
    component: ChinaSteelReportCreate,
    parent: ChinaSteelList,
    path: '/editorials/china-steel/create',
    title: 'Create Report'
  },
  {
    component: ChinaSteelReportDetail,
    parent: ChinaSteelList,
    path: '/editorials/china-steel/edit/:reportId',
    title: 'Report Detail'
  },
  {
    component: CompanyV2List,
    parent: null,
    path: '/companies',
    title: 'Companies'
  },
  {
    component: CompanyV2Create,
    parent: CompanyV2List,
    path: '/companies/create',
    title: 'Create'
  },
  {
    component: CompanyV2Detail,
    parent: CompanyV2List,
    path: '/companies/edit/:companyId',
    title: 'Detail'
  },
  {
    component: TradeExcelList,
    parent: null,
    path: '/editorials/trade-excels',
    title: 'Trade Excels'
  },
  {
    component: TradeExcelCreate,
    parent: TradeExcelList,
    path: '/editorials/trade-excels/create',
    title: 'Create'
  },
  {
    component: TradeExcelDetail,
    parent: TradeExcelList,
    path: '/editorials/trade-excels/edit/:tradeExcelId',
    title: 'Detail'
  },
  {
    component: CurrencyRateList,
    parent: null,
    path: '/editorials/currency-rates',
    title: 'Currency Rates'
  },
  {
    component: UnitConversionRateList,
    parent: null,
    path: '/editorials/unit-conversions',
    title: 'Unit Conversions'
  },
  {
    component: NewsletterReceiversList,
    parent: null,
    path: '/newsletters/receivers/:newsletter',
    title: 'Receivers'
  },
  {
    component: PriceSpreadList,
    parent: null,
    path: '/editorials/price-spreads/',
    title: 'Price Spreads'
  },
  {
    component: PriceSpreadDetail,
    parent: PriceSpreadList,
    path: '/editorials/price-spreads/edit/:priceSpreadId',
    title: 'Detail'
  },
  {
    component: PriceSpreadCreate,
    parent: PriceSpreadList,
    path: '/editorials/price-spreads/create',
    title: 'Create'
  },
  {
    component: PermanentPricesDetail,
    parent: null,
    path: '/editorials/permanent-prices/',
    title: 'Permanent prices'
  },
  {
    component: PriceSpreadMovementList,
    parent: null,
    path: '/editorials/price-series/price-spreads/movement',
    title: 'Price Spreads Movement'
  },
  {
    component: ExchangeRates,
    parent: CurrencyRateList,
    path: '/editorials/exchange-currency-rates/',
    title: 'Exchange Currency Rates'
  },
  {
    component: SEASMEditionList,
    parent: null,
    path: '/editorials/seasm-reports',
    title: 'SEASM Reports'
  },
  {
    component: SEASMEdtionCreate,
    parent: SEASMEditionList,
    path: '/editorials/seasm-reports/create',
    title: 'Create'
  },
  {
    component: SEASMEditionDetail,
    parent: SEASMEditionList,
    path: '/editorials/seasm-reports/edit/:id',
    title: 'Detail'
  },
  {
    component: NewsletterReceiversListV2,
    parent: null,
    path: '/v2/newsletters/receivers/:newsletter',
    title: 'Receivers'
  }
];

const coursesPages = [
  {
    component: CourseList,
    parent: null,
    path: '/courses',
    title: 'Events'
  },
  {
    component: CourseDetail,
    parent: null,
    path: '/courses/edit/:courseId',
    title: 'Event'
  },
  {
    component: CourseCreate,
    parent: CourseList,
    path: '/courses/create',
    title: 'Create event'
  }
];

const marketDataReportPages = [
  {
    component: MarketDataReportList,
    parent: null,
    path: '/market-data-report',
    title: 'Market data reports'
  },
  {
    component: MarketDataReportCreate,
    parent: MarketDataReportList,
    path: '/market-data-report/create',
    title: 'Create market data report'
  },
  {
    component: MarketDataReportDetail,
    parent: MarketDataReportList,
    path: '/market-data-report/edit/:reportId',
    title: 'Market data report'
  }
];

const adminToolsRoutes = [
  {
    component: ClientLoginInformationList,
    parent: null,
    path: '/admin-tools/client-information-logins',
    title: 'Client Logins'
  },
  {
    component: ClientLoginsList,
    parent: null,
    path: '/admin-tools/client-logins/:userId',
    title: 'Client All Login Attempts'
  },
  {
    component: LoginAttemptList,
    parent: null,
    path: '/admin-tools/login-attempts',
    title: 'Admin Login Attempts'
  },
  {
    component: GroupsAndPermissionsList,
    parent: null,
    path: '/admin-tools/groups',
    title: 'Groups and permissions'
  },
  {
    component: GroupsAndPermissionsCreate,
    parent: GroupsAndPermissionsList,
    path: '/admin-tools/groups/create',
    title: 'Create group and permissions'
  },
  {
    component: GroupsAndPermissionsDetail,
    parent: GroupsAndPermissionsList,
    path: '/admin-tools/groups/edit/:groupId',
    title: 'Update group and permissions'
  },
  {
    component: TrackedPriceSeriesOpens,
    parent: null,
    path: '/admin-tools/tracked-price-series-opens',
    title: 'Tracked Price Series Opens'
  },
  {
    component: TrackedArticlesList,
    parent: null,
    path: '/admin-tools/tracked-articles-opens',
    title: 'Tracked Articles Opens'
  },
  {
    component: ArticleChangesList,
    parent: null,
    path: '/admin-tools/article-changes',
    title: 'Article Changes'
  },
  {
    component: ArticleChangesAnalyticsList,
    parent: null,
    path: '/admin-tools/article-changes-analytics',
    title: 'Article Changes Analytics'
  },
  {
    component: OnlineUsersList,
    parent: null,
    path: '/admin-tools/online-users',
    title: "Who's online"
  }
];

const articleRoutes = [
  {
    component: WebArticleList,
    parent: null,
    path: '/articles/:productSlug',
    title: 'Articles'
  },
  {
    component: WebArticleCreate,
    parent: WebArticleList,
    path: '/articles/:productSlug/create',
    title: 'Create Article'
  },
  {
    component: WebArticleDetail,
    parent: WebArticleList,
    path: '/articles/:productSlug/:articleId',
    title: 'Article Detail'
  },
  {
    component: PriceSerieList,
    parent: null,
    path: '/price-series/:productSlug/',
    title: 'Price Series List'
  },
  {
    component: PriceSerieCreate,
    parent: PriceSerieList,
    path: '/price-series/:productSlug/create/',
    title: 'Create Price Series'
  },
  {
    component: PriceSerieMissingDataList,
    parent: null,
    path: '/price-series/:productSlug/missing-data/',
    title: 'Price Series Missing Data'
  },
  {
    component: PriceSerieMovementList,
    parent: PriceSerieList,
    path: '/price-series/:productSlug/movement/',
    title: 'Price Series Movement'
  },
  {
    component: PriceSerieUpdate,
    parent: null,
    path: '/price-series/:productSlug/update/',
    title: 'Price Serie Update'
  },
  {
    component: PriceSeriePublish,
    parent: PriceSerieList,
    path: '/price-series/:productSlug/publish/',
    title: 'Price Serie Publish'
  },
  {
    component: PriceSerieDetail,
    parent: PriceSerieList,
    path: '/price-series/:productSlug/:priceSeriesId/',
    title: 'Price Series Detail'
  },
  {
    component: PriceSpreadV2List,
    parent: null,
    path: '/price-spreads/:productSlug/',
    title: 'Price Spreads List'
  },
  {
    component: PriceSpreadV2Create,
    parent: PriceSpreadV2List,
    path: '/price-spreads/:productSlug/create/',
    title: 'Create Price Spreads'
  },
  {
    component: PriceSpreadV2Movement,
    parent: null,
    path: '/price-spreads/:productSlug/movement/',
    title: 'Price Spreads Movement'
  },
  {
    component: PriceSpreadV2Detail,
    parent: PriceSpreadV2List,
    path: '/price-spreads/:productSlug/:priceSpreadId/',
    title: 'Price Spread Detail'
  },
  {
    component: NewsletterList,
    parent: null,
    path: '/newsletters/:productSlug/',
    title: 'Newsletters'
  },
  {
    component: NewsletterCreate,
    parent: NewsletterList,
    path: '/newsletters/:productSlug/create/',
    title: 'Create Newsletter'
  },
  {
    component: NewsletterDetail,
    parent: NewsletterList,
    path: '/newsletters/:productSlug/:newsletterId/detail/',
    title: 'Newsletter Detail'
  },
  {
    component: NewsletterSetup,
    parent: null,
    path: '/newsletters/:productSlug/:newsletterType/edit-structure/',
    title: 'Newsletter Setup'
  },
  {
    component: ContentSection,
    parent: null,
    path: '/newsletters/:productSlug/:newsletterId/content-section/:sectionId?',
    title: 'Content Section'
  },
  {
    component: RecipientGroups,
    parent: null,
    path: '/newsletters/:productSlug/:newsletterType/recipient-groups/',
    title: 'Recipient Groups'
  },
  {
    component: NewsletterPermanentPrices,
    parent: null,
    path: '/newsletters/:productSlug/permanent-prices/',
    title: 'Newsletter permanent prices'
  },
  {
    component: PriceIndexList,
    parent: null,
    path: '/price-index/:productSlug/',
    title: 'Price Indexes'
  },
  {
    component: PriceIndexCreate,
    parent: PriceIndexList,
    path: '/price-index/:productSlug/create/',
    title: 'Create Price Index'
  },
  {
    component: PriceIndexDetail,
    parent: PriceIndexList,
    path: '/price-index/:productSlug/:priceIndexesId/',
    title: 'Price Index Detail'
  },
  {
    component: PriceIndexChart,
    parent: PriceIndexList,
    path: '/price-index/:productSlug/:priceIndexesId/chart',
    title: 'Price Indexes Chart'
  }
];

const routes = [
  {
    component: Login,
    parent: null,
    path: '/',
    title: 'Login'
  },
  {
    component: NotFound,
    parent: null,
    path: '/404',
    title: 'Not found'
  },
  {
    component: Maintenance,
    parent: null,
    path: '/maintenance',
    title: 'Maintenance'
  },
  ...dashboardPages,
  ...cmsPages,
  ...crmPages,
  ...emailPages,
  ...coursesPages,
  ...marketDataReportPages,
  ...editorialsPages,
  ...adminToolsRoutes,
  ...articleRoutes
];

const { pageUrl, pageBreadcrumb } = pageFactory(routes);

export { pageUrl, pageBreadcrumb };

const WrappedComponent = ({
  component,
  // Mainly we use the product slug in the URL so that we can prepend it in the page title
  paramKey = 'productSlug',
  ...props
}) => {
  const { params } = props.match;

  const breadcrumbs = useMemo(() => pageBreadcrumb(component, params), [
    component,
    params
  ]);

  useEffect(() => {
    if (component) {
      const titleParts = [];

      if (paramKey) {
        const paramValue = params[paramKey];

        if (paramValue) {
          titleParts.push(_.startCase(paramValue));
        }
      }

      titleParts.push(..._.map(breadcrumbs, 'title'));

      const title = _.join(titleParts, ' | ');

      document.title = `Kallanish Admin | ${title}`;
    }
  }, [component, breadcrumbs, params, paramKey]);

  const Component = component;

  return (
    <Component key={window.location.pathname} crumbs={breadcrumbs} {...props} />
  );
};

const Routes = () => {
  if (process.env.REACT_APP_MAINTENANCE === 'true') {
    return <Maintenance />;
  }

  return (
    <>
      <Router>
        <Switch>
          {routes.map(({ path, component, paramKey }, key) => (
            <Route
              exact
              key={key}
              path={path}
              render={props => (
                <WrappedComponent
                  {...props}
                  paramKey={paramKey}
                  component={component}
                />
              )}
            />
          ))}

          <Route path="/media/:path" component={RedirectToMedia} />
          <Route path="/admin" component={RedirectToDashboard} />
          <Route path="/en/admin" component={RedirectToDashboard} />
          <Route component={RedirectToNotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
