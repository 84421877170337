import React from 'react';

import { Formik } from 'formik';

import { Segment, Form, Statistic } from 'semantic-ui-react';

import { buildInitialValuesFromFilters } from 'utils/filters';

import {
  Field,
  InputField,
  DateField,
  FormRow,
  FilterButtons,
  AdminSelect
} from 'components';

import styles from './styles.module.css';

const FILTERS = [
  {
    name: 'client_id',
    emptyValue: ''
  },
  {
    name: 'updated_from',
    emptyValue: null
  },
  {
    name: 'updated_to',
    emptyValue: null
  },
  {
    name: 'staff_id',
    emptyValue: null
  }
];

class Filters extends React.Component {
  submitSearch = values => {
    this.props.filterBy(values);
  };

  resetSearch = resetForm => {
    const { clearFilters } = this.props;

    resetForm();
    clearFilters();
  };

  render() {
    const { filters, count } = this.props;

    const initialValues = buildInitialValuesFromFilters(filters, FILTERS);

    return (
      <Segment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.submitSearch}>
          {({ values, handleSubmit, resetForm }) => (
            <Form>
              <FormRow>
                <Field
                  name="client_id"
                  placeholder="Client ID"
                  component={InputField}
                  type="number"
                  label="Client ID"
                />

                <AdminSelect name="staff_id" label="Account Manager" />
              </FormRow>
              <FormRow>
                <Field
                  name="updated_from"
                  component={DateField}
                  label="Updated from"
                />
                <Field
                  name="updated_to"
                  component={DateField}
                  label="Updated to"
                />
              </FormRow>
              <div className={styles.footerRow}>
                <Statistic.Group size="tiny">
                  <Statistic color="green">
                    <Statistic.Value>{count}</Statistic.Value>
                    <Statistic.Label>Client profile logs</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
                <FilterButtons
                  searchAction={handleSubmit}
                  resetAction={() => this.resetSearch(resetForm)}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}

export default Filters;
